<template>
  <el-dialog
      :title="!dataForm.id ? '新增' : (flag == 'look' ? '查看' : '修改')"
      :close-on-click-modal="false"
      :visible.sync="visible">
    <el-form :model="dataForm" :disabled="flag=='look'" :rules="dataRule" ref="dataForm"
             @keyup.enter.native="dataFormSubmit()" label-width="80px">
      <el-form-item label="省级指标" prop="provinciallevelIndex">
        <el-input v-model="dataForm.provinciallevelIndex" placeholder="省级指标"></el-input>
      </el-form-item>
      <el-form-item label="汇总指标" prop="collectIndex">
        <el-input v-model="dataForm.collectIndex" placeholder="汇总指标"></el-input>
      </el-form-item>
      <el-form-item label="时间" prop="executeTime">
        <el-input v-model="dataForm.executeTime" placeholder="时间"></el-input>
      </el-form-item>
      <el-form-item label="执行状态" prop="executeType">
        <el-input v-model="dataForm.executeType" placeholder="执行状态"></el-input>
      </el-form-item>
      <el-form-item label="指标状态" prop="okrType">
        <el-input v-model="dataForm.okrType" placeholder="指标状态"></el-input>
      </el-form-item>


      <el-row>
        <el-col span="12">
          <el-form-item label="新增用户数" prop="addUserNumber">
            <el-input v-model="dataForm.addUserNumber" placeholder="新增用户数"></el-input>
          </el-form-item>
        </el-col>
        <el-col span="12">
          <el-form-item label="统计新增用户数" prop="addUserNumberCount">
            <el-input v-model="dataForm.addUserNumberCount" placeholder="统计新增用户数"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col span="12">
          <el-form-item label="增值收入" prop="valueAddedIncome">
            <el-input v-model="dataForm.valueAddedIncome" placeholder="增值收入"></el-input>
          </el-form-item>
        </el-col>
        <el-col span="12">
          <el-form-item label="统计增值收入" prop="valueAddedIncomeCount">
            <el-input v-model="dataForm.valueAddedIncomeCount" placeholder="统计增值收入"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col span="12">
          <el-form-item label="大众业务收入" prop="publicBusinessIncome">
            <el-input v-model="dataForm.publicBusinessIncome" placeholder="大众业务收入"></el-input>
          </el-form-item>
        </el-col>
        <el-col span="12">
          <el-form-item label="统计大众业务收入" prop="publicBusinessIncomeCount">
            <el-input v-model="dataForm.publicBusinessIncomeCount" placeholder="统计大众业务收入"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col span="12">
          <el-form-item label="5G发展用户" prop="developmentUser">
            <el-input v-model="dataForm.developmentUser" placeholder="5G发展用户"></el-input>
          </el-form-item>
        </el-col>
        <el-col span="12">
          <el-form-item label="统计5G发展用户" prop="developmentUserCount">
            <el-input v-model="dataForm.developmentUserCount" placeholder="统计5G用户发展用户"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer" v-loading="isOnSubmit">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()" :disabled="isOnSubmit">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import {getById, saveOrUpdate} from '@/api/dataflow/olxindicatorstrack.js'

export default {
  data() {
    return {
      visible: false,
      isOnSubmit: false,
      flag: '',
      dataForm: {
        id: null,
        provinciallevelIndex: '',
        collectIndex: '',
        executeTime: '',
        executeType: '',
        okrType: '',

        addUserNumber: '',
        valueAddedIncome: '',
        publicBusinessIncome: '',
        developmentUser: '',
        addUserNumberCount: '',
        valueAddedIncomeCount: '',
        publicBusinessIncomeCount: '',
        developmentUserCount: '',
      },
      dataRule: {
        provinciallevelIndex: [
          {required: true, message: '省级指标不能为空', trigger: 'blur'}
        ],
        collectIndex: [
          {required: true, message: '汇总指标不能为空', trigger: 'blur'}
        ],
        executeTime: [
          {required: true, message: '时间不能为空', trigger: 'blur'}
        ],
        executeType: [
          {required: true, message: '执行状态不能为空', trigger: 'blur'}
        ],
        okrType: [
          {required: true, message: '01-新增用户数，02-增值收入（产品的vip汇总），03-大众业务收入（普通套餐），04-5G发展用户不能为空', trigger: 'blur'}
        ]
      }
    }
  },
  methods: {
    init(id, flag) {
      this.dataForm.id = id
      this.visible = true
      this.flag = flag
      this.isOnSubmit = false
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields()
        if (this.dataForm.id) {
          getById(id).then(({data}) => {
            if (data && data.code === 0) {
              this.dataForm.provinciallevelIndex = data.data.provinciallevelIndex
              this.dataForm.collectIndex = data.data.collectIndex
              this.dataForm.executeTime = data.data.executeTime
              this.dataForm.executeType = data.data.executeType
              this.dataForm.okrType = data.data.okrType
              this.dataForm.addUserNumber = data.data.addUserNumber
              this.dataForm.valueAddedIncome = data.data.valueAddedIncome
              this.dataForm.publicBusinessIncome = data.data.publicBusinessIncome
              this.dataForm.developmentUser = data.data.developmentUser
              this.dataForm.addUserNumberCount = data.data.addUserNumberCount
              this.dataForm.publicBusinessIncomeCount = data.data.publicBusinessIncomeCount
              this.dataForm.developmentUserCount = data.data.developmentUserCount
            }
          }).catch((err) => {
            this.$message.error(err)
          })
        }
      })
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.isOnSubmit = true
          const data_ = {
            'id': this.dataForm.id || undefined,
            'provinciallevelIndex': this.dataForm.provinciallevelIndex,
            'collectIndex': this.dataForm.collectIndex,
            'executeTime': this.dataForm.executeTime,
            'executeType': this.dataForm.executeType,
            'okrType': this.dataForm.okrType
          }
          saveOrUpdate(data_).then(({data}) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 500
              })
              this.visible = false
              this.$emit('refreshDataList')
            } else {
              this.$message.error(data.msg)
            }
          }).catch((err) => {
            this.$message.error(err)
          }).finally(() => {
            this.isOnSubmit = false
          })
        }
      })
    }
  }
}
</script>
